<template>
  <div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 order-lg-3 order-xl-1">
        <KTPortlet v-bind:title="''">
          <template v-slot:title>
            <div v-if="quarter < 5">
              {{ $t("quarterMarks.title") }} - {{title}} // {{ $t("quarterMarks.quarter") }} - {{quarter}}
            </div>
            <div v-if="quarter == 5">
              {{ $t("quarterMarks.annualMark") }}  - {{title}}
            </div>
            <div v-if="quarter == 6">
              {{ $t("quarterMarks.examinationMarks") }}  - {{title}}
            </div>
            <div v-if="quarter == 7">
              {{ $t("quarterMarks.finalMarks") }}  - {{title}}
            </div>
          </template>
          <template v-slot:toolbar>
            <button class="btn btn-success" @click="download">Скачать</button>
          </template>
          <template v-slot:body>
            <b-tabs pills card>
              <b-tab>
                <template v-slot:title>
                  Успеваемость
                </template>
                <table class="table table-bordered">
                  <thead class="thead-light">
                  <tr>
                    <th width="5%" rowspan="2">#</th>
                    <th width="20%" rowspan="2" style="vertical-align: top">Ученики</th>
                    <th width="10%" :colspan="subjects.length">Предметы</th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                  </tr>
                  <tr>
                    <th v-for="(subject, subjectIndex) in subjects" :key="subjectIndex" class="rotate">
                      {{subject.name}}
                    </th>
                    <th class="rotate">ср.знач</th>
                    <th class="rotate">качество</th>
                    <th class="rotate">успеваемость</th>
                    <th class="rotate">cтатус</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(student, k) in students" :key="k">
                    <td>{{k + 1}}</td>
                    <td>{{student.name}}</td>
                    <td v-for="(subject, subjectIndex) in subjects" :key="subjectIndex">
                      {{ displayQuarterMark(marks[student.id][
                        subject.id
                        ])}}
                    </td>
                    <td>
                      {{student.averageMark.toFixed(2)}}
                    </td>    
                    <td>
                      {{student.quality.toFixed(2)}}
                    </td>       
                    <td>
                      {{student.progress.toFixed(2)}}
                    </td>   
                    <td>
                      {{student.status}}
                    </td>
                  </tr>

                  <tr>
                    <td></td>
                    <td>5</td>
                    <td v-for="(subject, subjectIndex) in subjects" :key="subjectIndex">
                      {{ subject.count5 }}
                    </td>
                    <td></td>
                  </tr>    
                  <tr>
                    <td></td>
                    <td>4</td>
                    <td v-for="(subject, subjectIndex) in subjects" :key="subjectIndex">
                      {{ subject.count4 }}
                    </td>
                    <td></td>
                  </tr>    
                  <tr>
                    <td></td>
                    <td>3</td>
                    <td v-for="(subject, subjectIndex) in subjects" :key="subjectIndex">
                      {{ subject.count3 }}
                    </td>
                    <td></td>
                  </tr> 
                  <tr>
                    <td></td>
                    <td>2</td>
                    <td v-for="(subject, subjectIndex) in subjects" :key="subjectIndex">
                      {{ subject.count2 }}
                    </td>
                    <td></td>
                  </tr>   
                  <tr>
                    <td></td>
                    <td>Н/А</td>
                    <td v-for="(subject, subjectIndex) in subjects" :key="subjectIndex">
                      {{ subject.countNa }}
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>Средний бал %</td>
                    <td v-for="(subject, subjectIndex) in subjects" :key="subjectIndex">
                      {{subject.averageMark.toFixed(2)}}
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>Качество знаний %</td>
                    <td v-for="(subject, subjectIndex) in subjects" :key="subjectIndex">
                      {{subject.quality.toFixed(2)}}
                    </td>
                    <td></td>
                  </tr>  
                  <tr>
                    <td></td>
                    <td>Успеваемость %</td>
                    <td v-for="(subject, subjectIndex) in subjects" :key="subjectIndex">
                      {{subject.progress.toFixed(2)}}
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>СОУ %</td>
                    <td v-for="(subject, subjectIndex) in subjects" :key="subjectIndex">
                      {{subject.sou.toFixed(2)}}
                    </td>
                  </tr>
                  </tbody>
                </table>
                <table class="table table-bordered" width="400px">
                  <tr>
                    <td width="200px">На начало четверти </td>
                    <td>
                      {{studentQuarterStartCount}}
                    </td>
                  </tr>
                  <tr>
                    <td>На конец четверти </td>
                    <td>
                      {{studentQuarterEndCount}}
                    </td>
                  </tr>
                  <tr>
                    <td>ФИ выбывших учащихся</td>
                    <td>
                      {{droppedStudentsNames.join(", ")}}
                    </td>
                  </tr>
                  <tr>
                    <td>Успевают</td>
                    <td>
                      {{withGoodMarks}}
                    </td>
                  </tr>
                  <tr>
                    <td>Не успевают</td>
                    <td>
                      {{withMarks2}}
                    </td>
                  </tr>
                  <tr>
                    <td>Ф.И не успевающих</td>
                    <td>
                      {{withMarks2Names}}
                    </td>
                  </tr>
                  <tr>
                    <td>И предмет</td>
                    <td>
                      {{withMarks2Subjects}}
                    </td>
                  </tr>
                  <tr>
                    <td>Успевают на отлично</td>
                    <td>
                      {{withMarks5}}
                    </td>
                  </tr>
                  <tr>
                    <td>Успевают на хорошо</td>
                    <td>
                      {{withMarks4}}
                    </td>
                  </tr>
                  <tr>
                    <td>Успевают на удовлетворительно</td>
                    <td>
                      {{withMarks3}}
                    </td>
                  </tr>
                  <tr>
                    <td>Качество знаний %</td>
                    <td>
                      {{marksQualityAverage.toFixed(2)}}
                    </td>
                  </tr>
                  <tr>
                    <td>Успеваемость %</td>
                    <td>
                      {{marksProgressAverage.toFixed(2)}}
                    </td>
                  </tr>
                  <tr>
                    <td>Средний балл</td>
                    <td>
                      {{marksAverageForSubjectAverage.toFixed(2)}}
                    </td>
                  </tr>    
                  <tr>
                    <td>СОУ %</td>
                    <td>
                      {{marksSouAverage.toFixed(2)}}
                    </td>
                  </tr>
                </table>
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  Статистика по оценкам
                </template>
                <table class="table table-bordered">
                  <thead class="thead-light">
                  <tr>
                    <th width="5%" rowspan="2">#</th>
                    <th width="20%" rowspan="2" style="vertical-align: top">Ученики</th>
                    <th colspan="14">Статистика по оценкам</th>
                  </tr>
                  <tr>
                    <th class="">5</th>
                    <th class="">4</th>
                    <th class="">3</th>
                    <th class="">2</th>
                    <th class="">н/а</th>
                    <th class="rotate">отличник</th>
                    <th class="rotate">ударник</th>
                    <th class="rotate">троечник</th>
                    <th class="rotate">двоечник</th>
                    <th class="rotate">н/а</th>
                    <th class="rotate">с одной 4</th>
                    <th class="rotate">с одной 3</th>
                    <th class="rotate">с одной 2</th>
                    <th class="rotate">с двумя 2</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(student, k) in students" :key="k">
                    <td>{{k + 1}}</td>
                    <td>{{student.name}}</td>
                    <td>{{student.count5}}</td>
                    <td>{{student.count4}}</td>
                    <td>{{student.count3}}</td>
                    <td>{{student.count2}}</td>
                    <td>{{student.countNa}}</td>
                    <td>
                      {{student.statusMark == 5 ? "Да": "Нет"}}
                    </td>  
                    <td>
                      {{student.statusMark == 4 ? "Да": "Нет"}}
                    </td>
                    <td>
                      {{student.statusMark == 3 ? "Да": "Нет"}}
                    </td>
                    <td>
                      {{student.statusMark == 2 ? "Да": "Нет"}}
                    </td>
                    <td>
                      {{student.isNa ? "Да": "Нет"}}
                    </td>
                    <td>
                      {{student.withOne4 ? "Да": "Нет"}}
                    </td> 
                    <td>
                      {{student.withOne3 ? "Да": "Нет"}}
                    </td>    
                    <td>
                      {{student.withOne2 ? "Да": "Нет"}}
                    </td>   
                    <td>
                      {{student.withTwo2 ? "Да": "Нет"}}
                    </td>
                  </tr>
                  <tr>
                    <td colspan="7">
                      Итого
                    </td>
                    <td>
                      {{data.certified5}}
                    </td>
                    <td>
                      {{data.certified4}}
                    </td>     
                    <td>
                      {{data.certified3}}
                    </td>  
                    <td>
                      {{data.certified2}}
                    </td>  
                    <td>
                      {{data.certifiedNa}}
                    </td>     
                    <td>
                      {{data.certifiedWithOne4}}
                    </td>
                    <td>
                      {{data.certifiedWithOne3}}
                    </td>
                    <td>
                      {{data.certifiedWithOne2}}
                    </td>
                    <td>
                      {{data.certifiedWithTwo2}}
                    </td>
                  </tr>
                  </tbody>
                </table>
                <table class="table table-bordered">
                  <thead class="thead-light">
                  <tr>                    
                    <th>
                    </th>      
                    <th>
                      Всего
                    </th>
                    <th>
                      Мальчиков
                    </th>      
                    <th>
                      Девочек
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>Количество учащихся к началу четверти</td>
                    <td>{{data.startStudentsCountBySex.allCount}}</td>
                    <td>{{data.startStudentsCountBySex.boysCount}}</td>
                    <td>{{data.startStudentsCountBySex.girlsCount}}</td>                    
                  </tr>
                  <tr>
                    <td>Выбыло</td>
                    <td>{{data.leavingCountBySex.allCount}}</td>
                    <td>{{data.leavingCountBySex.boysCount}}</td>
                    <td>{{data.leavingCountBySex.girlsCount}}</td>
                  </tr>
                  <tr>
                    <td>Прибыло</td>
                    <td>{{data.incomeCountBySex.allCount}}</td>
                    <td>{{data.incomeCountBySex.boysCount}}</td>
                    <td>{{data.incomeCountBySex.girlsCount}}</td>
                  </tr>
                  <tr>
                    <td>Количество учащихся к концу четверти</td>
                    <td>{{data.endStudentsCountBySex.allCount}}</td>
                    <td>{{data.endStudentsCountBySex.boysCount}}</td>
                    <td>{{data.endStudentsCountBySex.girlsCount}}</td>
                  </tr>  
                  <tr>
                    <td>Отличники</td>
                    <td>{{data.certifiedMark5StudentsCountBySex.allCount}}</td>
                    <td>{{data.certifiedMark5StudentsCountBySex.boysCount}}</td>
                    <td>{{data.certifiedMark5StudentsCountBySex.girlsCount}}</td>
                  </tr>    
                  <tr>
                    <td>Ударники</td>
                    <td>{{data.certifiedMark4StudentsCountBySex.allCount}}</td>
                    <td>{{data.certifiedMark4StudentsCountBySex.boysCount}}</td>
                    <td>{{data.certifiedMark4StudentsCountBySex.girlsCount}}</td>
                  </tr>
                  </tbody>
                </table>
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  Успеваемость по фамильно по показаиелям
                </template>
                <table class="table table-bordered">
                  <thead class="thead-light">
                  <tr>
                    <th>Показатель</th>
                    <th>Количество учашихся</th>
                    <th>Пофамильно</th>
                    <th>Предмет и ФИО учителя</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>Всего учашихся</td>
                    <td>{{data.quarterEndStudentsCount}}</td>
                  </tr>
                  <tr>
                    <td :rowspan="data.certified5StudentNames.length + 1">Отличников</td>
                    <td :rowspan="data.certified5StudentNames.length + 1">{{data.certified5StudentNames.length}}</td>
                       
                    <td>
                    </td>
                  </tr>
                  <tr v-for="(student, studenttIndex) in data.certified5StudentNames" :key="studenttIndex">
                    <td>{{student}}</td>
                    <td></td>
                  </tr>
                  
                  <tr>
                    <td :rowspan="data.certifiedWithOne4List.length + 1">С одной 4 по дисциплине</td>
                    <td :rowspan="data.certifiedWithOne4List.length + 1">{{data.certifiedWithOne4List.length}}</td>
                  </tr>
                  <tr v-for="(student, studenttIndex) in data.certifiedWithOne4List" :key="studenttIndex">
                    <td>{{student.studentName}}</td>
                    <td>
                      {{student.subjects.join()}}
                    </td>
                  </tr>
                  
                  <tr>
                    <td :rowspan="data.certifiedMark4StudentNames.length + 1">Ударников</td>
                    <td :rowspan="data.certifiedMark4StudentNames.length + 1">{{data.certifiedMark4StudentNames.length}}</td>
                  </tr>
                  <tr v-for="(student, studenttIndex) in data.certifiedMark4StudentNames" :key="studenttIndex">
                    <td>{{student}}</td>
                    <td></td>
                  </tr>

                  <tr>
                    <td :rowspan="data.certifiedWithOne3List.length + 1">С одной 3 по дисциплине</td>
                    <td :rowspan="data.certifiedWithOne3List.length + 1">{{data.certifiedWithOne3List.length}}</td>
                  </tr>
                  <tr v-for="(student, studenttIndex) in data.certifiedWithOne3List" :key="studenttIndex">
                    <td>{{student.studentName}}</td>
                    <td>
                      {{student.subjects.join()}}
                    </td>
                  </tr>

                  <tr>
                    <td :rowspan="data.certifiedMark3StudentNames.length + 1">Троечников</td>
                    <td :rowspan="data.certifiedMark3StudentNames.length + 1">{{data.certifiedMark3StudentNames.length}}</td>
                  </tr>
                  <tr v-for="(student, studenttIndex) in data.certifiedMark3StudentNames" :key="studenttIndex">
                    <td>{{student}}</td>
                    <td></td>
                  </tr>

                  <tr>
                    <td :rowspan="data.certifiedMark2StudentNames.length + 1">Двоечников</td>
                    <td :rowspan="data.certifiedMark2StudentNames.length + 1">{{data.certifiedMark2StudentNames.length}}</td>
                  </tr>
                  <tr v-for="(student, studenttIndex) in data.certifiedMark2StudentNames" :key="studenttIndex">
                    <td>{{student}}</td>
                    <td></td>
                  </tr>
                  
                  <tr>
                    <td :rowspan="data.certifiedWithOne2List.length + 1">Не успевающие по одному предмету</td>
                    <td :rowspan="data.certifiedWithOne2List.length + 1">{{data.certifiedWithOne2List.length}}</td>
                  </tr>
                  <tr v-for="(student, studenttIndex) in data.certifiedWithOne2List" :key="studenttIndex">
                    <td>{{student.studentName}}</td>
                    <td>
                      {{student.subjects.join()}}
                    </td>
                  </tr>
                  
                  <tr>
                    <td :rowspan="data.certifiedWithTwo2List.length + 1">Не успевающие по двум предметам</td>
                    <td :rowspan="data.certifiedWithTwo2List.length + 1">{{data.certifiedWithTwo2List.length}}</td>
                  </tr>
                  <tr v-for="(student, studenttIndex) in data.certifiedWithTwo2List" :key="studenttIndex">
                    <td>{{student.studentName}}</td>
                    <td>
                      {{student.subjects.join()}}
                    </td>
                  </tr>       
                  
                  <tr>
                    <td :rowspan="data.certifiedWithMore2List.length + 1">Не успевающие по трем и более предметам</td>
                    <td :rowspan="data.certifiedWithMore2List.length + 1">{{data.certifiedWithMore2List.length}}</td>
                  </tr>
                  <tr v-for="(student, studenttIndex) in data.certifiedWithMore2List" :key="studenttIndex">
                    <td>{{student.studentName}}</td>
                    <td>
                      {{student.subjects.join()}}
                    </td>
                  </tr>
                  </tbody>
                </table>
              </b-tab>
            </b-tabs>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<style>
  .nav-tabs{
    display: none;
  }

/*  table, th, td {
    border: 1px solid black;
  }*/
  .hw-title{
    font-style: italic;
  }

  .mobile-day-select{
    display: none;
  }
  
  @media (max-width: 767px) {

    #marksTabs div.col-auto{
      display: none;
    }

    .mobile-day-select{
      display: block;
    }
  }
  th.rotate {
    writing-mode: vertical-rl;
  }


</style>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import DictionariesService from "@/common/dictionaries.service";


export default {
  name: "quarterMarks",
  components: {
    KTPortlet
  },
  data() {
    return {
      redraw: 0,
      title: '',
      quarter: '',
      students:[],
      subjects:[],
      marksList: [
        { value: 2, text: "2" },
        { value: 3, text: "3" },
        { value: 4, text: "4" },
        { value: 5, text: "5" }
      ],
      marks: {},
      marksAverageForStudent:{},
      mark5Count:{},
      mark4Count:{},
      mark3Count:{},
      marksQuality:{},
      marksProgress:{},
      marksSou:{},
      marksAverageForSubject:{},
      
      studentQuarterStartCount: 0,
      droppedStudentsNames: [],
      studentQuarterEndCount: 0,
      studentComeNames: {},
      withGoodMarks: 0,
      withMarks2: 0,
      withMarksNA: 0,
      withMarks2Names: '',
      withMarks2Subjects: '',
      withMarks5: 0,
      withMarks4: 0,
      withMarks3: 0,
      marksQualityAverage: 0,
      marksProgressAverage: 0,
      marksAverageForSubjectAverage: 0,
      marksSouAverage: 0,
      data:{},
    };
  },
  mounted() {
    this.reloadData();
  },
  methods: {
    reloadData(){
      let $this = this;      
      let params = {
        quarter : this.$route.params.quarter,
        groupId: this.$route.params.groupId
      }
      ApiService.postSecured("marks/getQuarterMarks", params)  
          .then(({ data }) => {
            $this.data = data;
            $this.title = data.groupName;
            $this.quarter = data.quarterName;
            $this.subjects = data.subjectNames;
            $this.students = data.studentNames;

            $this.marks = {};

            for (let i in $this.students) {
              let student = $this.students[i];

              $this.marks[student.id] = {};
            }
            for (let ii in data.marks){
              let mark  = data.marks[ii];

              if( typeof $this.marks[mark.studentId] != 'undefined'){
                $this.marks[mark.studentId][mark.subjectId] = mark.mark;
              }
            }
            $this.studentQuarterStartCount = data.quarterStartStudentsCount;
            $this.studentQuarterEndCount = data.quarterEndStudentsCount;
            
            $this.droppedStudentsNames = data.droppedStudentsNames;
            $this.withGoodMarks = data.certified5 + data.certified4 + data.certified3;
            $this.withMarks2 = data.certified2;
            $this.withMarks2Names = data.certifiedMark2StudentNames.join(", ");
            $this.withMarks2Subjects = "";
            $this.withMarks5 = data.certified5;
            $this.withMarks4 = data.certified4;
            $this.withMarks3 = data.certified3;
            $this.marksAverageForSubjectAverage = data.averageMark;
            $this.marksQualityAverage = data.quality;
            $this.marksProgressAverage = data.progress;
            $this.marksSouAverage = data.sou;

          })
          .catch(({ response }) => {
            console.log(response);
          });
    },
    countStat(){
      let marksAverageForStudent = {};
      let marks5 = {};
      let marks4 = {};
      let marks3 = {};
      let marks2 = [];
      let marks2Subject = {};
      let marksNA = {};
      
      let marks5Students = [];
      let marks4Students = [];
      let marks3Students = [];
      let marks2Students = [];
      let marksNAStudents = [];
      
      let marksQuality = {};
      let marksProgress = {};
      let marksSou = {};
      let marksAverageForSubject = {};
      for (let subjectIndex in this.subjects){    
        let subjectId = this.subjects[subjectIndex].subjectId;
        marks5[subjectId] = 0;
        marks4[subjectId] = 0;
        marks3[subjectId] = 0;
        marks2Subject[subjectId] = 0;
        marksNA[subjectId] = 0;        
        marksQuality[subjectId] = 0;
        marksProgress[subjectId] = 0;
        marksAverageForSubject[subjectId] = 0;
      }
      
      for (let stIndex in this.students){
        let student = this.students[stIndex];
        let cc = 0;
        let marksSum = 0;
        for (let subjectId  in this.marks[student.studentId]){
          let mark = parseInt(this.marks[student.studentId][subjectId]); 
          if ( mark > 1) {
            marksSum += mark;
            cc++;
            switch (mark){
              case 5:
                marks5[subjectId] += 1;
                if(!marks5Students.includes(student.studentId)){
                  marks5Students.push(student.studentId);
                }
                break;
              case 4:
                marks4[subjectId] += 1;
                if(!marks4Students.includes(student.studentId)){
                  marks4Students.push(student.studentId);
                }
                break;
              case 3:
                marks3[subjectId] += 1;
                if(!marks3Students.includes(student.studentId)){
                  marks3Students.push(student.studentId);
                }
                break;
              case 2:
                marks2Subject[subjectId] += 1;
                if(!marks2.includes(subjectId)){
                  marks2.push(parseInt(subjectId));
                }
                if(!marks2Students.includes(student.studentId)){
                  marks2Students.push(student.studentId);
                }                
                break;
              case 8:
                marksNA[subjectId] += 1;
                if(!marksNAStudents.includes(student.studentId)){
                  marksNAStudents.push(student.studentId);
                }
                break;
              default:
                break;                
            }
          }       
        }      
        
        if(cc > 0){
          marksAverageForStudent[student.studentId] = (marksSum/cc).toPrecision(2);
        }        
      }

      this.withMarks5 = 0;
      this.withMarks4 = 0;
      this.withMarks3 = 0;
      this.withMarks2 = 0;
      this.withMarksNA = 0;

      for (let index5 in marks5Students){
        let item = marks5Students[index5];
        if(
            !marks2Students.includes(item)
            && !marks3Students.includes(item)
            && !marks4Students.includes(item)
        ){
          this.withMarks5 = this.withMarks5 +1;
        }
      }

      for (let index5 in marks4Students){
        let item = marks5Students[index5];
        if(
            !marks2Students.includes(item)
            && !marks3Students.includes(item)
        ){
          this.withMarks4 = this.withMarks4 +1;
        }
      }

      for (let index5 in marks3Students){
        let item = marks5Students[index5];
        if(
            !marks2Students.includes(item)
        ){
          this.withMarks3 = this.withMarks3 +1;
        }
      }

      let studentWith2List = [];
      for (let item in this.students){
        let student = this.students[item];
        if(marks2Students.includes(student.studentId)){
          studentWith2List.push(student.studentName);
        }
      }
      this.withMarks2Names = studentWith2List.toString();

      this.withMarks2 = marks2Students.length;
      this.withGoodMarks = this.withMarks5 + this.withMarks4 + this.withMarks3;

      let subjectsWith2List = [];
      let marksQualityAverage = 0;
      let marksProgressAverage = 0;
      let marksAverageForSubjectAverage = 0;
      let studentsCount = this.students.length;
      if(studentsCount > 0){
        for (let subjectIndex in this.subjects){
          let subject = this.subjects[subjectIndex];
          let subjectId = subject.subjectId;
          if(marks2.includes(subjectId)){
            subjectsWith2List.push(subject.subjectName);
          }

          marksQuality[subjectId] = (100 * (marks5[subjectId] + marks4[subjectId]) / studentsCount);
          marksProgress[subjectId] = (100 * (marks5[subjectId] + marks4[subjectId] + marks3[subjectId]) / studentsCount);
          marksSou[subjectId] = (100 * marks5[subjectId] + 64 * marks4[subjectId] + 36 * marks3[subjectId] + 16 * marks2Subject[subjectId] + 7 * marksNA[subjectId]) / studentsCount;
          marksAverageForSubject[subjectId] = ((marks5[subjectId] * 5 + marks4[subjectId] * 4 + marks3[subjectId] * 3) / studentsCount);

          marksQualityAverage += marksQuality[subjectId];
          marksProgressAverage += marksProgress[subjectId];
          marksAverageForSubjectAverage += marksAverageForSubject[subjectId];

          /*marksQuality[subjectId] = marksQuality[subjectId].toFixed(2);
          marksProgress[subjectId] =  marksProgress[subjectId].toFixed(2);
          marksAverageForSubject[subjectId] = marksAverageForSubject[subjectId].toFixed(2);*/
        }
      }
      let subjectsCount = this.subjects.length;
      if(subjectsCount > 0){
        this.marksQualityAverage = (marksQualityAverage/subjectsCount).toPrecision(2);
        this.marksProgressAverage = (marksProgressAverage/subjectsCount).toPrecision(2);
        this.marksAverageForSubjectAverage = (marksAverageForSubjectAverage/subjectsCount).toPrecision(2);
      }
      
      this.withMarks2Subjects = subjectsWith2List.toString();
      //this.studentQuarterStartCount = this.students.length;
      this.studentQuarterEndCount = this.students.length;
      this.studentComeNames = '';
      
      this.marksAverageForStudent = marksAverageForStudent;
      this.mark5Count = marks5;
      this.mark4Count = marks4;
      this.mark3Count = marks3;
      this.marksQuality = marksQuality;
      this.marksProgress = marksProgress;
      this.marksSou = marksSou;
      this.marksAverageForSubject = marksAverageForSubject;
    },
    download(){
      let params = {
        quarter : this.$route.params.quarter,
        groupId: this.$route.params.groupId
      }
      let $this = this;
      ApiService.downloadSecured("marks/downloadQuarterMark", params)
          .then(({data}) => {
            const url = URL.createObjectURL(new Blob([data], {
              type: 'application/vnd.ms-excel'
            }));
            const link = document.createElement('a');
            link.href = url;
            
            link.setAttribute('download', 'Ведомость ' + $this.title + '.xlsx');
            document.body.appendChild(link);
            link.click();
          })
          .catch(({ response }) => {
            console.log(response);
          });
    },
    displayQuarterMark(mark){
      return DictionariesService.displayQuarterMark(mark);
    },
  }
};
</script>
